<template>
  <div id="main">
    <header class="header">
      <img src="@/assets/logo_a.png" class="logo_head">
      <p class="slogan">Bewährter Geschmack<br>
        in neuem Design.</p>
    </header>
    <div class="cols">
      <div id="logoContainer">
        <img src="@/assets/logo_w.svg" id="logo2" class="logo logo_w">
        <img src="@/assets/umsgspirs_sw.svg" id="logo1" class="logo umsgspirs">
      </div>
      <div class="spacer"></div>
      <div class="infoText">
        Öffnungszeiten ab Hof<br>
        MO–FR<div class="space_lg"></div>08:30–17:00<br>
        telefonische Bestellung erforderlich<br>
        <br>
        Hofkäserei Bramberger<br>
        Höhenweg 2, 4442 Kleinraming<br>
        <br>
        <table class="dataTable">
          <tr>
            <td>T</td><td><a href="tel:+43725230958">07252 30958</a></td>
          </tr>
          <tr>
            <td>M</td><td><a href="tel:+436642812811">0664 2812811</a></td>
          </tr>
          <tr>
            <td>E</td><td><a href="mailto:office@spirs.at">office@spirs.at</a><br></td>
          </tr>
        </table>

        <p class="maintenance">Website im Aufbau...</p>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import {Component, Vue} from "vue-property-decorator";

@Component({
  components: {}
})
export default class Home extends Vue {

  mounted() {
    this.animate();
  }

  animate() {
    const duration = 1;
    const delay = 8;

    const t = gsap.timeline({
      id: "Everything"
    });
    t.add(this.getLogoTimeline(duration, delay), "+=" + ((delay+duration) / 2));
    t.add(this.getColorsTimeline(duration, delay), "0");
    /*
    GSDevTools.create({
      animation: "Everything",
      hideGlobalTimeline: false,
      css: "z-index: 20"
    });
     */
  }

  getLogoTimeline(duration, delay) {
    const els = ["#logo1", "#logo2"];
    const l = gsap.timeline({
      id: "Logo",
      repeat: -1
    });
    for (let i = 0; i < els.length; i++) {
      const show = els[i];
      const hide = els[(i + 1) % els.length];
      const label = hide + " -> " + show;
      const ease = "linear";
      l.addLabel(label, "+=" + 0)
          .to(show, {
            opacity: 1,
            duration: duration,
            ease: ease
          }, label)
          .to(hide, {
            opacity: 0,
            duration: duration,
            ease: ease
          }, label)
          .to(show, {}, "+=" + delay); // Wait
    }
    return l;
  }

  getColorsTimeline(duration, delay) {
    const colors = ["#859fa3", "#99a26f", "#ed694b"];

    const el = "#main";

    const c = gsap.timeline({
      id: "Colors",
      repeat: -1
    });
    for (let color of colors) {
      c.to(el, {
        backgroundColor: color,
        duration: duration,
        ease: "linear"
      }, "+=" + (duration+delay));
    }
    return c;
  }

}
</script>

<style scoped lang="scss">
#main {
  min-height: 100vh;
  width: 100vw;
  background-color: #ed694b;
  overflow: hidden;

  font-size: 24px;

  @media screen and (max-width: 1200px) {
    font-size: 20px;
  };

  .header {
    display: flex;
    position: absolute;
    $margin: calc(min(5vw, 5vh));
    top: $margin;
    left: $margin;
    align-items: flex-end;

    .logo_head {
      height: 15vh;
      max-height: 20vw;
    }

    .slogan {
      width: 500px;
      bottom: 0;
      margin: 0;
      font-size: 1.4em;
      padding-left: 30px;

      color: white;
      text-transform: uppercase;

      @media screen and (max-width: 500px) {
        font-size: 20px;
      }

      @media screen and (max-width: 400px) {
        font-size: 18px;
      }

      @media screen and (max-width: 350px) {
        font-size: 16px;
      }
    }
  }

  $mobileWidth: 850px;

  .cols {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 10vw;

    #logoContainer {
      flex: 0 1 auto;
      position: relative;

      .logo {
        height: 290px;
        position: absolute;
        left: 0;
        top: 0;
        transform: translateY(-50%);

        @media screen and (max-width: 1200px) {
            height: 240px;
        }
      }

      #logo1 {
        opacity: 0;
      }
    }

    .spacer {
      flex: 1 0 auto;
    }

    .infoText {
      flex: 0 1 auto;
      position: relative;
      white-space: pre-wrap;

      .dataTable {

        td {
          padding: 0;

          &:first-of-type {
            padding-right: 4px;
          }
        }
      }

      a {
        color: inherit;
        text-decoration: none;
      }
    }

    .maintenance {
      color: white;
      font-size: 1.4em;
      position: absolute;
      text-transform: uppercase;

      bottom: -150px;
      transform: translateX(-50%);
    }
  }

  @media screen and (max-width: $mobileWidth) {
    .header {
      position: relative;
    }

    .cols {
      flex-direction: column;
      height: 100%;

      #logoContainer, .infoText {

      }

      $logoHeightMobile: 30vh;

      #logoContainer {
        $margin: 30px;
        margin-top: $margin;
        margin-bottom: $margin;
        height: $logoHeightMobile;
        width: 100% !important;
        max-width: 80vw;
      }

      .logo {
        top: 50% !important;
        left: 50% !important;
        width: 100%;
        max-height: $logoHeightMobile;
        transform: translate(-50%, -50%) !important;
      }

      #logo2 {
        height: auto !important;
        width: 281px !important;
      }

      .infoText {
      }

      .maintenance {
        position: relative;
        bottom: unset;
        transform: none;
      }
    }

  }

  .space_lg {
    display: inline-block;
    height: 10px;
    width: 20px;
  }
}
</style>
